import React, { useEffect } from "react"

const AdUnit = ({ client, unitId, fullWidthResponsive }) => {
  useEffect(() => {
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  })
  return (
    <div>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client={client}
        data-ad-slot={unitId}
        data-ad-format="auto"
        data-full-width-responsive={fullWidthResponsive}
      ></ins>
    </div>
  )
}

export default AdUnit
